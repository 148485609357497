
  import { Lead } from "@/models/leads";
  import { Household } from "@/models/households";
  import { CareReceiver } from "@/models/care_receivers";
  import { Leadsource } from "@/models/leadsources";
  import { User } from "@/models/users";
  import { List } from "@/models/lists";
  import TitleActionsNavbar from "@/components/shared/TitleActionsNavbar.vue";
  import Logs from "@/components/shared/Logs.vue";
  import Address from "@/components/shared/Address.vue";
  import AddressCard from "@/components/shared/AddressCard.vue";
  import Erfassungsbogen from "@/components/shared/CareReceiverEdit.vue"
  import { defineComponent, ref, watch, computed, getCurrentInstance } from "vue"
  import { provideRefreshData } from '@/utils/refreshData'
  import { resourceUrl, fullName } from '@/utils/dataExtractors'
  import { apiDataMorpher, apiDataMerger, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { CollectionResourceDoc, SingleResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { Log } from "@/models/logs"
  import moment from 'moment'
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import Tickets from "@/components/shared/Tickets.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import { internalEditLeadSchema } from "@/view_scripts/form_schemas/lead";
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import CrCreate from "@/components/shared/CareReceiverCreate.vue"
  import LeadKeyData from "@/components/internal/LeadKeyData.vue"
  import { leadHouseholdsColumns } from "@/view_scripts/table_columns/household_columns"
  import { getConfirmationModal } from "@/plugins/confirmation"

  interface Props {
    id: string
  }

  interface hhOption {
    value: string|null|undefined,
    text?: string
  }

  export default defineComponent({
    components: {
      Address,
      AddressCard,
      Logs,
      Erfassungsbogen,
      TitleActionsNavbar,
      TicketEdit,
      Tickets,
      SchemaForm,
      CrCreate,
      LeadKeyData
    },
    name: "internal_lead_show",
    props: {
      id: {
        type: String,
        required: true
      }
    },

    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const lead = computed<ResourceObject<string, Lead>>(() => {
        const ld = root.$store.state.leads.element
        if (ld?.attributes?.tickets) {
          ticketIds.value = ld.attributes.tickets.map((t)=>t.id)
        }
        return ld
      })
      const showLoading = ref(false)
      const leadResponse = computed(() => root.$store.state.leads.elementResponse)
      const logs = computed<ResourceObject<string, Log>[]>(() => {
        return root.$store.state.leads.elementResponse?.included.filter(inc => inc.type === 'logs')
          .map(inc => ({...inc.attributes, id: inc.id, type: inc.type}))
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
      })
      const households = computed<ResourceObject<string, Household>[]>(() => root.$store.state.households.data)
      const freeCrs = computed<ResourceObject<string, CareReceiver>[]>(() => {
        return root.$store.state.care_receivers.data.filter((cr: ResourceObject<string, CareReceiver>) => {
          return (cr.relationships?.household as RelationshipsWithData)?.data === undefined
        })
      })

      const hhOptions = computed<hhOption[]>(() => {
        const defaultOptions: hhOption[] = [
          {value: undefined, text: 'Bitte einen Haushalt wählen'},
          {value: 'Neu', text: 'Neuen Haushalt erstellen'}
        ]
        const options: hhOption[] = households.value.map((hh:ResourceObject<string, Household>) => {
          return { value: hh.id, text: hh.attributes?.designation }
        })
        return defaultOptions.concat(options)
      })
      const newTicketModal = ref()
      const careReceiverId = ref<string>()
      const ticketIds = ref<string[]>([])
      const crCreateModal = ref(false)

      const refreshData = () => {
        const leadQueryParams = {
          'fields[addresses]': 'all',
          'fields[emails]': 'all',
          'fields[phones]': 'all',
          'fields[seller]': 'all',
          'fields[tickets]': 'all',
          'fields[logs]': 'all',
          include: 'address.emails,address.phones,protoLeadsource,seller,tickets,logs'
        }
        root.$store.dispatch('leads/getv2', { id: props.id, queryParams: leadQueryParams })
        // root.$store.dispatch('leads/get', { id: props.id, queryParams: { include: 'address,proto_leadsource,seller,tickets' } })
      }

      watch(leadResponse, (newValue) => {
        const hhIds = extractRelationshipIds(newValue, 'households').join(',')
        root.$store.dispatch('households/load', {'filter[id]': hhIds, include: 'care_receivers'})
      })

      provideRefreshData(refreshData)
      refreshData()

      const titleActions = ref([
        {
          title: "Löschung",
          icon: "trash",
          tooltip: "Testlead löschen",
          onClick: () => testLeadRemove(props.id)
        },
        {
          title: "DSGVO",
          icon: 'comment-exclamation',
          tooltip: "DSGVO-Löschung",
          onClick: () => dsgvoRemove(props.id)
        },
        {
          title: 'Bearbeiten',
          icon: 'pencil-alt',
          tooltip: 'Lead Bearbeiten',
          onClick: () => editModal.value.show()
        },
        newTicketAction(newTicketModal),
        {
          title: 'PB erstellen',
          icon: 'user-plus',
          tooltip: 'Eine neue pflegebedüftige Person hinzufügen',
          onClick: () => crCreateModal.value = true
        }
      ])

      const assignHousehold = async (cr: ResourceObject<string, CareReceiver>) => {
        if (cr.id) {
          const data = {
            attributes: { householdId: cr.attributes?.householdId },
            usecase: 'household_assign'
          }
          await root.$store.dispatch('care_receivers/edit', { id: cr.id, body: data })
          refreshData()
        }
      }

      const editModal = ref()
      const leadsources = computed<CollectionResourceDoc<string, Leadsource>>(() => root.$store.state.proto_leadsources.data)
      const sellers = computed<CollectionResourceDoc<string, User>>(() => {
        return root.$store.state.users.data.filter((s)=>{
          return s.attributes.active
        })
      })
      const reclaimReasons = computed<string[]>(() => {
        return root.$store.state.lists.data.reduce((collector, parentList) => {
          return collector.concat(parentList.attributes?.sublists?.map((sl) => sl.attributes.name) || [])
        }, [])
      })
      const leadsourceQueryParams = {
        'filter[_type]': 'leadsource'
      }
      root.$store.dispatch('proto_leadsources/load', leadsourceQueryParams)
      const sellerQueryParams = {
        'filter[_type]': 'Seller',
        'page[size]': 500,
        'page[number]': 1,
      }

      const listQueryParams = {
        'filter[sysId]': 'lead_reclaim_reasons,id_lead_reclaim_reasons',
        'page[size]': 25,
        'include': 'sublists'
      }
      root.$store.dispatch('users/load', sellerQueryParams)
      root.$store.dispatch('lists/load', listQueryParams)
      // const form = ref<ResourceObject<string, Lead>>({ type: 'leads', attributes: { cost: 0, price: 30 } })

      const uiSchema = computed(() => {
        return {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json",
          $vocabulary: {
            "https://json-schema.org/draft/2019-09/vocab/core": true,
            "https://crm.pflegehilfe-senioren.de/ui-vocab": true
          },
          "ui:type": "form",
          "ui:widget": "b-row",
          type: "object",
          properties: {
            attributes: {
              type: "object",
              $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes",
              "ui:type": "group",
              "ui:label": "",
              properties: {
                // notes: {
                //   "ui:label": "Notes",
                //   "ui:widget": "b-textarea",
                //   $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/notes"
                // },
                sellerId: {
                  "ui:type": "collection",
                  "ui:widget": "v-select",
                  "ui:label": "Vertrieb",
                  "ui:options": {
                    options: sellers.value ?? [],
                    reduce: seller => seller.id,
                    getOptionLabel: opt => fullName(opt)
                  },
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/sellerId",
                  items: {
                    type: "string",
                    $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/sellerId/items"
                  }
                },
                protoLeadsourceId: {
                  "ui:type": "collection",
                  "ui:widget": "v-select",
                  "ui:label": "Leadquelle",
                  "ui:options": {
                    options: leadsources.value ?? [],
                    reduce: leadsource => leadsource.id,
                    getOptionLabel: opt => opt.attributes.displayName
                  },
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/protoLeadsourceId",
                  items: {
                    type: "string",
                    $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/protoLeadsourceId/items"
                  }
                },
                reclaimReason: {
                  "ui:type": "collection",
                  "ui:widget": "v-select",
                  "ui:label": "Reklamationsgrund",
                  "ui:options": {
                    options: reclaimReasons.value ?? [],
                    reduce: e => e,
                    getOptionLabel: opt => opt
                  },
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/reclaimReason",
                  items: {
                    type: "string",
                    $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/reclaimReason/items"
                  }
                },
                cost: {
                  "ui:label": "Kosten",
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/cost",
                  "ui:options": {
                    type: "number"
                  },
                  "ui:classNames": ["col-12", "col-md-6"]
                },
                price: {
                  "ui:label": "Preis",
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/price",
                  "ui:options": {
                    type: "number"
                  },
                  "ui:classNames": ["col-12", "col-md-6"]
                },
                archived: {
                  "ui:label": "Archiviert",
                  "ui:type": "checkbox",
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/archived",
                  "ui:classNames": ["col-4"]
                },
                reclaimed: {
                  "ui:label": "Reklamiert",
                  "ui:type": "checkbox",
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/reclaimed",
                  "ui:classNames": ["col-4"]
                },
                reclaimConfirmed: {
                  "ui:label": "Reklammation bestätigt",
                  "ui:type": "checkbox",
                  $ref: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json#/properties/attributes/properties/reclaimConfirmed",
                  "ui:classNames": ["col-4"]
                },
              }
            }
          }
        }
      })

      const saveLead = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        showLoading.value = true
        await root.$store.dispatch('leads/edit', { id: props.id, body: {attributes: lead.value.attributes} })
        root.$nextTick(() => {
          refreshData()
          showLoading.value = false
          editModal.value.hide()
        })
      }

      const updateLead = async (data: any) => {
        showLoading.value = true
        await root.$store.dispatch('leads/edit', { id: props.id, body: { attributes: data } })
        refreshData()
        showLoading.value = false
      }

      const ticketCreated = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      const confirmationModal = getConfirmationModal()

      const dsgvoRemove = (id: string) => {
        confirmationModal.confirmation(
          "Die personenbezogene Information dieses Kunden wird entfernt und der Lead wird archiviert.",
          async () => {
            if (id) {
              const data = {
                usecase: 'dsgvo_remove'
              }
              await root.$store.dispatch('leads/edit', { id, body: data })
            }
          })
      }

      const testLeadRemove = (id: string) => {
        confirmationModal.confirmation(
          "Bitte nur Testleads löschen. Leads sollten nur gelöscht werden, wenn sie zu trainingszwecken oder aus versehen erstellt wurden.",
          async () => {
            if (id) {
              const data = {
                usecase: 'test_lead_destroy'
              }
              await root.$store.dispatch('leads/edit', { id, body: data })
            }
          })
      }

      return {
        showLoading,
        lead,
        logs,
        saveLead,
        updateLead,
        internalEditLeadSchema,
        uiSchema,
        editModal,
        households,
        freeCrs,
        hhOptions,
        fullName,
        titleActions,
        refreshData,
        moment,
        resourceUrl,
        assignHousehold,
        newTicketModal,
        crCreateModal,
        ticketIds,
        ticketCreated,
        leadsources,
        leadHouseholdsColumns,
      }
    }
  })
